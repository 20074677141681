<template>
  <div :class="$style.home">
    <div :class="$style.tip">
      <div :class="$style.icon">
        <i class="ri-emotion-line" />
      </div>
      <div :class="$style.text">
        <div :class="$style.name">同步内容</div>
        <div :class="$style.desc">
          同步内容是指将其他自媒体（如微信公众号）内容同步至文化云，然后按需转至内容管理模块发布。同步内容需要先自媒体账号授权至文化云，请在“文化传播-媒体管理”模块中管理。
        </div>
      </div>
    </div>
    <div :class="$style.content">
      <div :class="$style.title">
        分发列表

        <a-dropdown>
          <bj-button :class="$style.btn" type="primary" class="type">
            同步内容
            <i class="ri-arrow-down-s-line" />
          </bj-button>
          <template #overlay>
            <a-menu>
              <a-menu-item v-for="item in typeData" :key="item.nickname" :class="$style.drop" @click="onSync(item)">
                <img :class="$style.dropIcon" :src="item.icon" />
                同步“{{ item.nickname }}”
              </a-menu-item>
            </a-menu>
          </template>
        </a-dropdown>
      </div>
      <a-table :loading="loading" :columns="columns" :data-source="data" :pagination="false" row-key="id">
        <template #title="item">
          <div>
            <img v-if="item.cover" :class="$style.cover" :src="item.cover.cover" />
            {{ item.title }}
          </div>
        </template>
        <template #content_type="item">
          <div>
            {{ item.content_type | typeName }}
          </div>
        </template>
        <template #status="item">
          <div>
            {{ item.status ? '上架' : '下架' }}
          </div>
        </template>
        <template #action="item">
          <bj-link type="primary" @click="onPreview(item)"> 详情 </bj-link>
          <a-popconfirm title="确定要删除吗？" @confirm="onDelete(item)">
            <bj-link type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import { spreadManageService } from '@/service'

const service = new spreadManageService()

export default {
  name: 'Home',
  filters: {
    typeName(val) {
      switch (val) {
        case 'post':
          return '图文'
        case 'audio':
          return '音频'
        case 'video':
          return '视频'
        case 'image':
          return '图册'
      }
    },
  },
  data() {
    return {
      page: 1,
      loading: false,
      data: [],
      typeData: [],
    }
  },
  computed: {
    columns() {
      return [
        { title: '内容名称', dataIndex: 'title' },
        { title: '内容形式', dataIndex: 'content_type' },
        { title: '作者', dataIndex: 'author' },
        { title: '同步时间', dataIndex: 'created_at' },
        { title: '状态', dataIndex: 'status' },
        { title: '管理', dataIndex: 'action' },
      ]
    },
  },
  created() {
    this.getList()
    this.getType()
  },
  methods: {
    async getType() {
      try {
        const { data } = await service.availableSync()
        this.typeData = data
      } catch (e) {}
    },
    async onSync(item) {
      try {
        await service.taskSyncList({
          platform_type: item.platform_type,
          media_id: item.id,
        })
        this.$message.success('同步任务创建成功，请稍后查看')
      } catch (e) {}
    },
    async getList() {
      try {
        const { data } = await service.getSyncList({
          page: this.page,
          pageSize: 999,
        })
        this.data = data.record
      } catch (e) {}
    },
  },
}
</script>

<style lang="less" module>
.home {
  .content {
    background: #fff;
    padding: 20px;
    margin-top: 20px;
    .title {
      font-size: 16px;
      color: #000;
      margin-bottom: 20px;

      .btn {
        float: right;
      }
    }
  }

  .tip {
    display: flex;
    align-items: center;
    background: #fff;
    padding: 20px;
    .icon {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background: #fafafa;
      margin-right: 10px;
      text-align: center;
      font-size: 20px;
      line-height: 50px;
    }

    .text {
      flex: 1;
      font-size: 12px;
      color: #5c5c5c;
      .name {
        font-size: 16px;
        color: #000;
        margin-bottom: 4px;
      }
    }
  }
}

.cover {
  display: flex;
  align-items: center;

  img {
    width: 70px;
    height: 44px;
    object-fit: cover;
    border-radius: 6px;
    margin-right: 10px;
  }
}
.drop {
  display: flex;
  align-items: center;
  .dropIcon {
    width: 20px;
    height: 20px;
    object-fit: cover;
    margin-right: 5px;
  }
}
</style>
